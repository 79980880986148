/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import Flexfooter from '../../../assets/Image_Cambios/flexware.svg'
import Facebook from '../../../assets/MuckUps Desktop/D-Screens _ Home/Elements/Desktop/Footer/Fill 9.svg'
import Linkedin from '../../../assets/MuckUps Desktop/D-Screens _ Home/Elements/Desktop/Footer/Group 2.svg'
function Footer() {
    const containerfoot = {
        height: '104px',
        display: 'flex'
    }
    const cont1 = {
        width: '50%',
        display: 'grid',
        fontFamily: 'Carme, sans-serif',
        justifyContent: 'start',
        alignItems: 'end',
        marginLeft: '10px',
    }
    const cont2 = {
        width: '50%',
        display: 'grid',
        justifyContent: 'end',
        alignItems: 'center',
        marginRight: '10px',
    }
    const redes = {
        width: '',
        display: 'flex',
        justifyContent: 'space-around',
    }
    const politicas = {
        color: '#8E2721',
        fontFamily: 'Carme, sans-serif',
        fontSize: '14px',
        lineHeight: '18px',
        textAlign: 'right',
    }
    return (
        <>
            <section style={containerfoot}>
                <section style={cont1}>
                    <img src={Flexfooter} style={{ width: '7vw' }} alt="logo Atdac" />
                    <p>FLEXWARE®. Derechos reservados 2022</p>
                </section>
                <section style={cont2}>
                    <div style={redes}>
                        <Link to='https://www.facebook.com/Flexwaremx/' target='_blank' style={{ textDecoration: 'none' }}><img src={Facebook} alt="Facebook" /></Link>
                        <Link to='https://www.linkedin.com/company/flexware-mx/mycompany/' target='_blank' style={{ textDecoration: 'none' }}><img src={Linkedin} alt="Linkedin" /></Link>
                    </div>
                    <Link to="/Politicas" style={{ textDecoration: 'none' }} target='_blank'> <p style={politicas} >
                        Políticas de privacidad
                    </p></Link>

                </section>
            </section>
        </>
    )
}

export default Footer;