import I_Soluciones from '../../assets/Flexware/SOLUCIONES TECNOLOGICAS .svg'
import ProfServ from '../../assets/Flexware/SERVICIOS PROFESIONALES.svg'
import InfraS from '../../assets/Flexware/INFRAESTRUCTURA.svg'
import Aplicativos from '../../assets/Flexware/APLICATIVOS .svg'
import Automatizacion from '../../assets/Flexware/AUTOMATIZACIÓN DE PROCESO.svg'

// import { Costumers, Contactanos } from '../Layout/Shared/Shared'
import { Contactanos, Costumers } from '../Layout/Shared/Shared'


function Soluciones() {
    const block1 = {
        display: 'flex',
        justifyContent: 'space-evenly',
    }
    const block1_1 = {
        height: 'auto',
        width: '30vw',
        display: 'flex',
        color: 'white',
        fontFamily: 'Gilroy-Regular, sans-serif',
        fontSize: '5vw',
        alignItems: 'center',
    }
    const block1_2 = {
        height: 'auto',
        width: '35vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
    const block2 = {
        height: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '2vw'
    }
    const block2_1 = {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
    // const block2_1_1 = {
    //     width: '90%',
    //     display: 'flex',
    //     height: '100px',
    //     justifyContent: 'space-between',
    //     alignItems: 'center',
    //     marginLeft: '20px',
    // }
    const block2_1_2 = {
        width: '100%',
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '20px'
    }
    const block2_2 = {
        height: '90%',
        width: '70%',
        display: 'flex',
        color: '#FFFFFF',
        fontFamily: 'Gilroy-Regular, sans-serif',
        fontSize: '75px',
        justifyContent: 'center',
        alignItems: 'center',
    }
    const block3 = {
        height: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        paddingBottom: '2vw'
    }
    const block3_1_1 = {
        width: '90%',
        display: 'flex',
        height: '100px',
        alignItems: 'center',
        marginLeft: '20px',
    }
    const block3_1_2 = {
        width: '90%',
        display: 'flex',
        height: '100px',
        alignItems: 'center',
        marginLeft: '20px'
    }
    const block3_1_3 = {
        width: '90%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '20px',
    }
    const contenedor = {
        width: '80%',
        height: '100%',
        display: 'grid',
    }
    return (
        <>

            <section style={{ display: 'flex', justifyContent: 'center', background: '#666666' }}>
                <section style={contenedor} className='contenido'>
                    <section style={block1} className='contenido2'>
                        <section style={block1_1} className='sblock1'>
                            <p>Soluciones Tecnológicas <b>Disruptivas</b></p>
                        </section>
                        <section style={block1_2} className='content'>
                            <img src={I_Soluciones} style={{
                                width: '70%',
                                height: 'auto',
                                padding: '30px'
                            }} alt="Illustracion_Soluciones" />
                        </section>
                    </section>
                </section>
            </section>
            <section style={{ display: 'grid', justifyItems: 'center', width: '100%', paddingTop: '1vw' }}>
                <section style={contenedor} className='blockcont1'>
                    <section style={block3} className='blockcont'>
                        <section style={block2_1} className='blockcont'>
                            <section style={block3_1_1} className='fcont'>
                                <div style={{ display: 'flex', alignItems: 'flex-start', width: '50%' }}>
                                    <div style={{
                                        height: '30px',
                                        width: '22px',
                                        backgroundColor: '#236C37',
                                        marginRight: '10px',
                                        marginTop: '5px'
                                    }}></div>
                                    <p style={{
                                        width: '50%',
                                        display: 'grid',
                                        fontFamily: 'Gilroy-Regular, sans-serif',
                                        fontSize: '30px',
                                        alignItems: 'center',
                                        margin: '0'
                                    }} className='content'> <b>Automatización</b></p>
                                </div>
                                <section style={{ width: '20%' }}>

                                </section>
                            </section>
                            <section style={block3_1_2} className='fcont'>
                                <p style={{
                                    width: '90%',
                                    color: '#000000',
                                    display: 'grid',
                                    fontFamily: 'Gilroy-Regular, sans-serif',
                                    fontSize: '19px',
                                    alignItems: 'center',
                                    margin: '0',
                                }}>  Flexware provee soluciones para automatización de procesos que
                                    permitan agilizar las tareas operativas de los trabajadores
                                    impulsando la productividad.</p>
                            </section>
                            <section style={block3_1_3} className='fcont'>
                                <section style={{
                                    color: '#000000',
                                    display: 'block',
                                    fontFamily: 'Gilroy-Regular, sans-serif',
                                    fontSize: '17px',
                                    alignItems: 'center',
                                }} >
                                    <p><b>Servicios</b></p>
                                    <li>Entrenamiento en desarrollo de Robots de Software.</li>
                                    <li>Análisis de descubrimiento de procesos a Automatizar con RPA.</li>
                                    <li> Entrenamiento Rocketbot.</li>
                                    <li>Bolsa de Horas de Soporte Rocketbot.</li>
                                    <li>Servicios de Profesionales de Desarrollo de Robots RPA con Rocketbot.</li>
                                </section>
                            </section>
                        </section>
                        <section style={block2_2} className='imgsoluciones'>
                            <img src={Automatizacion} alt='FullStackImg' style={{ height: '325px', width: '80%', }} className='imgsoluciones' />
                        </section>
                    </section>
                    {/* ________________________________________________________________________________ */}
                    <section style={block2} className='blockcont'>
                        <section style={block2_1} className='contenido1'>
                            <section style={block3_1_3} className='fcont'>
                                <div style={{ display: 'flex', alignItems: 'flex-start', width: '50%' }} >
                                    <div style={{
                                        height: '30px',
                                        width: '22px',
                                        backgroundColor: '#58B14B',
                                        marginRight: '10px',
                                        marginTop: '5px'
                                    }}></div>
                                    <p style={{
                                        width: '80%',
                                        color: '#000000',
                                        display: 'grid',
                                        fontFamily: 'Gilroy-Regular, sans-serif',
                                        fontSize: '30px',
                                        alignItems: 'center',
                                        margin: '0'
                                    }}> <b>Servicio Profesionales</b></p>
                                </div>
                                <section style={{ width: '20%' }}>

                                </section>
                            </section>
                            <section style={block2_1_2} className='fcont'>
                                <p style={{
                                    width: '90%',
                                    color: '#000000',
                                    display: 'grid',
                                    fontFamily: 'Gilroy-Regular, sans-serif',
                                    fontSize: '19px',
                                    alignItems: 'center',
                                }}> Flexware ofrece servicios profesionales bajo buenas prácticas
                                    que hemos desarrollado a través de los años y experiencia en
                                    Flexware utilizando metodologías agiles para la entrega exitosa
                                    de cada proyecto. </p>
                            </section>
                            <section style={block3_1_3} className='fcont'>
                                <section style={{
                                    width: '100%',
                                    color: '#000000',
                                    display: 'block',
                                    fontFamily: 'Gilroy-Regular, sans-serif',
                                    fontSize: '18px',
                                    alignItems: 'center',
                                }}>
                                    <p><b>Servicios</b></p>
                                    <li>Servicios de Migración a Nuevas Versiones Progress o Sistemas Operativos</li>
                                    <li>Implementación de Replicación para estrategias de DRP.</li>
                                    <li>Modernización de Aplicaciones a Ambientes Web y Móviles</li>
                                    <li>Integración de Progress con Sistemas de Terceros.</li>
                                    <li>Healthcheck Progress.</li>
                                    <li>Servicios Profesionales de Desarrollo </li>
                                    <li>Auditorías Blancas de Revisión de Licenciamiento Progress.</li>
                                    <li>Bolsas de Horas de Soporte Progress</li>
                                    <li>Automatización y Transferencia segura de archivos</li>

                                </section>
                            </section>
                        </section>
                        <section style={block2_2} className='imgsoluciones'>
                            <img src={ProfServ} alt='FullStackImg' style={{ height: '325px', width: '70%' }} className='imgsoluciones' />
                        </section>
                    </section>

                    {/* ________________________________________________________________________________ */}
                    <section style={block3} className='blockcont'>
                        <section style={block2_1} className='blockcont'>
                            <section style={block3_1_1} className='fcont'>
                                <div style={{ display: 'flex', alignItems: 'flex-start', width: '50%' }}>
                                    <div style={{
                                        height: '30px',
                                        width: '22px',
                                        backgroundColor: '#236C37',
                                        marginRight: '10px',
                                        marginTop: '5px'
                                    }}></div>
                                    <p style={{
                                        width: '50%',
                                        display: 'grid',
                                        fontFamily: 'Gilroy-Regular, sans-serif',
                                        fontSize: '30px',
                                        alignItems: 'center',
                                        margin: '0'
                                    }} className='content'> <b>Infraestructura</b></p>
                                </div>
                                <section style={{ width: '20%' }}>

                                </section>
                            </section>
                            <section style={block3_1_2} className='fcont'>
                                <p style={{
                                    width: '90%',
                                    color: '#000000',
                                    display: 'grid',
                                    fontFamily: 'Gilroy-Regular, sans-serif',
                                    fontSize: '19px',
                                    alignItems: 'center',
                                    margin: '0',
                                }}>  Flexware provee soluciones para infraestructura como
                                    base de datos, administración multinube, monitoreo de
                                    dispositivos y acceso remoto.</p>
                            </section>
                            <section style={block3_1_3} className='fcont'>
                                <section style={{
                                    color: '#000000',
                                    display: 'block',
                                    fontFamily: 'Gilroy-Regular, sans-serif',
                                    fontSize: '17px',
                                    alignItems: 'center',
                                }} >
                                    <p><b>Servicios</b></p>
                                    <li>Monitoreo de Aplicaciones.</li>
                                    <li>Monitoreo de Dispositivos de Red.</li>
                                    <li> Mesa de Ayuda.</li>
                                    <li>Supervisión de Disponibilidad y Rendimiento de la Red.</li>
                                </section>
                            </section>
                        </section>
                        <section style={block2_2} className='imgsoluciones'>
                            <img src={InfraS} alt='FullStackImg' style={{ height: '325px', width: '80%', }} className='imgsoluciones' />
                        </section>
                    </section>

                    {/* ________________________________________________________________________________ */}
                    <section style={block2} className='blockcont'>
                        <section style={block2_1} className='blockcont'>
                            <section style={block3_1_1} className='fcont'>
                                <div style={{ display: 'flex', alignItems: 'flex-start', width: '50%' }}>
                                    <div style={{
                                        height: '30px',
                                        width: '22px',
                                        backgroundColor: '#58B14B',
                                        marginRight: '10px',
                                        marginTop: '5px'
                                    }}></div>
                                    <p style={{
                                        width: '80%',
                                        color: '#000000',
                                        display: 'grid',
                                        fontFamily: 'Gilroy-Regular, sans-serif',
                                        fontSize: '30px',
                                        alignItems: 'center',
                                        margin: '0'
                                    }}> <b>Aplicativos</b></p>
                                </div>
                                <section style={{ width: '20%' }}>

                                </section>
                            </section>
                            <section style={block2_1_2} className='fcont'>
                                <p style={{
                                    width: '90%',
                                    color: '#000000',
                                    display: 'grid',
                                    fontFamily: 'Gilroy-Regular, sans-serif',
                                    fontSize: '18px',
                                    alignItems: 'center',
                                }}> Flexware provee una plataforma de espacio de trabajo flexible,
                                    reserva de escritorio  reserva de habitaciones, gestión de
                                    estacionamientos, reserva de taquilla inteligente,
                                    distanciamiento social, lista de verificación digital, planos de piso
                                    3D,catálogo de servicios, ocupación del espacio de trabajo,
                                    analítica.</p>
                            </section>
                            <section style={block3_1_3} className='fcont'>
                                <section style={{
                                    color: '#000000',
                                    display: 'grid',
                                    fontFamily: 'Gilroy-Regular, sans-serif',
                                    fontSize: '18px',
                                    alignItems: 'center',
                                }}>
                                    <p><b>Alcances</b></p>
                                    <li>Logramos el trabajo ágil, trabajo basado en actividades,
                                        distanciamiento social.
                                    </li>
                                    <li>Reducir el costo de bienes raíces por empleado con información
                                        sobre la ocupación de oficinas.
                                    </li>
                                    <li>Mejorar la productividad adoptando el lugar de trabajo híbrido/
                                        oficina digital.
                                    </li>
                                    <li>Salud y seguridad para los empleados.</li>
                                    <li>Plan de piso digital gemelo, programación de escritorio, rotación.</li>
                                    <li>Informar problemas, cableado, catering, limpieza.</li>
                                </section>
                            </section>
                        </section>
                        <section style={block2_2} className='imgsoluciones'>
                            <img src={Aplicativos} alt='FullStackImg' style={{ height: '325px', width: '70%' }} className='imgsoluciones' />
                        </section>
                    </section>
                    {/* ________________________________________________________________________________ */}

                </section>
            </section>
            <Costumers />
            <Contactanos />
        </>
    )
}

export default Soluciones;