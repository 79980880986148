import { Link } from 'react-router-dom';
import { Component } from 'react';

import Icon from '../../../assets/Image_Cambios/flexware.svg'
class Navbar extends Component {
    state = { clicked: false }
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {

        const logo = {
            display: 'grid',
            width: '300px',
            height: '60px',
            alignSelf: 'center',
            marginLeft: '5%',
        }
        const nav = {
            height: '60px',
            width: '100%',
            backgroundColor: '#F5F5F5',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }
        const home = {
            height: '24px',
            width: '100px',
            color: '#236C37',
            fontFamily: 'Gilroy-ExtraBold, sans-serif',
            fontSize: '14px',
            fontWeight: '800',
            letterSpacing: '2.00px',
            lineHeight: '24px',
            textAlign: 'center',
            textDecoration: 'none',
        }
        const nosotros = {
            height: '24px',
            width: '122px',
            color: '#236C37',
            fontFamily: 'Gilroy-ExtraBold, sans-serif',
            fontSize: '14px',
            fontWeight: '800',
            letterSpacing: '2.00px',
            lineHeight: '24px',
            textAlign: 'center',
            textDecoration: 'none',
        }
        const soluciones = {
            width: '138px',
            color: '#236C37',
            fontFamily: 'Gilroy-ExtraBold, sans-serif',
            fontSize: '14px',
            fontWeight: '800',
            letterSpacing: '2.00px',
            lineHeight: '24px',
            textAlign: 'center',
            textDecoration: 'none',
        }
        // const xoc = {
        //     height: '24px',
        //     width: '122px',
        //     color: '#236C37',
        //     fontFamily: 'Gilroy-ExtraBold, sans-serif',
        //     fontSize: '14px',
        //     fontWeight: '800',
        //     letterSpacing: '2.00px',
        //     lineHeight: '24px',
        //     textAlign: 'center',
        //     textDecoration: 'none',
        // }
        const contacto = {
            height: '24px',
            width: '122px',
            color: '#236C37',
            fontFamily: 'Gilroy-ExtraBold, sans-serif',
            fontSize: '14px',
            fontWeight: '800',
            letterSpacing: '2.00px',
            lineHeight: '24px',
            textAlign: 'center',
            textDecoration: 'none',
        }
        const ul = {
            listStyle: 'none',
            display: 'flex',
            marginRight: '10%',
            justifyContent: 'center',
            alignItems: 'center',
        }
        const svg = {
            width: '40%',
        }
        return (
            <nav style={nav} id='navbar' >
                <div style={logo}>
                    <Link to='/Home' style={{ display: 'flex' }}> <img src={Icon} alt="logo" style={svg} /></Link>
                </div>
                <div style={ul} id='links' className={this.state.clicked ? '#links active' : "#links"}>
                    <Link to="/Home" style={home} className='direct' onClick={this.handleClick}> HOME </Link>
                    <Link to="/Nosotros" style={nosotros} className='direct' onClick={this.handleClick}> NOSOTROS </Link>
                    <Link to="/Soluciones" style={soluciones} className='direct' onClick={this.handleClick}> SOLUCIONES </Link>

                    <Link to="/Contacto" style={contacto} className='direct' onClick={this.handleClick}> CONTACTO</Link>
                </div>
                <div id='mobile' onClick={this.handleClick}>
                    <i id='bar' className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>
            </nav>
        )
    }
}
export default Navbar