import Trusted from '../../assets/Image_Cambios/queienssomos.svg'
import Pilares from '../../assets/Flexware/TRANSFORMACIÓN DIGITAL.svg'
import ServiciosP from '../../assets/Image_Cambios/servicios_prof.svg'
import Infra from '../../assets/Image_Cambios/infraestructura.svg'
import ServP from '../../assets/Image_Cambios/aplicativos.svg'
import Auto from '../../assets/Image_Cambios/automatizacion.svg'
import FlexL from '../../assets/Pagina Home Flexware/logoblancoflexware.svg'
// import { Partnerships, Costumers, Contactanos } from '../Layout/Shared/Shared'
import { Partnerships, Contactanos } from '../Layout/Shared/Shared'
import { Link } from 'react-router-dom';
function Home() {
    const container = {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    }
    const container2 = {
        width: '100%',
        height: '100%',
        display: 'grid',
        justifyItems: 'center',
    }
    const Fblock = {
        height: 'auto',
        width: '100%',
        backgroundColor: '#56b14b',
        margin: '0',
        padding: '0',
        display: 'grid',
        justifyContent: 'center'
    }
    const Ftext = {
        width: '80%',
        color: '#FFFFFF',
        fontSize: '70px',
        fontWeight: '80px',
        display: 'block',
        lineHeight: '40px',
        marginTop: '20px',
        marginLeft: '10px',
        height: 'auto'
    }
    const trusted = {
        fontFamily: 'Carme, sans-serif',
        margin: '0px',
        fontSize: '40px',
        lineHeight: '50px',
        textAlign: 'center',
        color: 'White',
        height: '100px'
    }
    const Stext = {
        fontFamily: 'Carme, sans-serif',
        height: '30%',
        width: '100%',
        color: 'white',
        fontWeight: '10',
        letterSpacing: '0',
        lineHeight: '30px',
        marginLeft: '10px',
        textAlign: 'center',
        fontSize: '20px'
    }
    const buttons = {
        display: 'flex',
        margin: '1vw',
    }
    const buttonLeft = {
        height: '40px',
        width: '204px',
        backgroundColor: '#4DB8B5',
        border: 'none',
        color: '#FFFFFF',
        marginRight: '23px',
        fontFamily: 'Carme, sans-serif',
        cursor: 'pointer',
    }
    const buttonRight = {
        boxSizing: ' border-box',
        height: ' 40px',
        width: ' 204px',
        border: ' 3px solid #FFFFFF',
        color: ' #FFFFFF',
        backgroundColor: ' transparent',
        fontFamily: 'Carme, sans-serif',
        cursor: 'pointer',
    }
    const containerText = {
        display: 'flex',
        justifyContent: 'center',
        width: ' 40%',
        height: '100%',
    }
    const containerSvg = {
        display: ' flex',
        alignItems: ' center',
        justifyContent: ' center',
        height: 'auto',
        width: ' 40%',
    }
    const title = {
        color: ' #000000',
        fontFamily: 'Carme,sans-serif',
        fontSize: ' 40px',
        letterSpacing: ' 0',
        lineHeight: ' 30px',
        textAlign: ' center',
    }
    const paragraf = {
        height: ' 100%',
        width: '70%',
        color: ' #000000',
        fontFamily: 'Carme,sans-serif',
        fontSize: ' 30px',
        letterSpacing: '',
        lineHeight: ' 40px',
        textAlign: ' center',
    }
    const sBlock = {
        display: 'grid',
        justifyContent: 'center',
        justifyItems: 'center'
    }
    const sBlock2 = {
        display: 'grid',
        justifyItems: 'center',
        paddingBottom: '30px'
    }
    const adn = {
        width: '30%',
    }
    const tBlock = {
        height: 'auto',
        display: 'grid',
        justifyItems: 'center'
    }
    const EnATDAC = {
        height: ' 50%',
        width: ' 60%',
        display: ' grid',
        textAlign: ' center',
        justifyItems: 'center',
    }
    const fPart = {
        width: ' 90%',
        color: ' #58B14B',
        fontFamily: 'Carme,sans-serif',
        fontSize: ' 40px',
        lineHeight: ' 10px',
        textAlign: ' center',
    }
    const sPart = {
        height: ' 100%',
        width: ' 70 %',
        color: ' #58B14B',
        fontFamily: 'Carme,sans-serif',
        fontSize: ' 25px',
        letterSpacing: ' 0',
        lineHeight: ' 30px',
        textAlign: ' center',
    }
    const fullStack = {
        width: ' 22%',
        color: ' #000000',
        fontFamily: 'Carme,sans-serif',
        fontSize: ' 16px',
        letterSpacing: ' 0',
        lineHeight: ' 20px',
        marginTop: ' 2%',
    }

    const cBlock = {
        display: ' flex',
        width: '  100%',
        justifyContent: ' space-around',
    }
    const iconsSolucions = {
        width: ' 40px',
        height: ' 40px',
    }
    const iconS = {
        width: ' 30px',
        height: ' 30px',
        marginLeft: ' 10%',

    }
    const paragrafSolucions = {
        width: ' 100%',
        height: ' 60%',
        textAlign: 'justify',

    }
    const buttonS = {
        height: ' 40px',
        width: ' 100%',
        backgroundColor: '#666666',
        border: 'none',
        color: ' #FFFFFF',
        fontFamily: 'Carme,sans-serif',
    }
    const container3 = {
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-around',
    }
    return (
        <>
            <div style={Fblock} className='contenido'>
                <section style={container} className='contenido2' >
                    <div style={containerText} className='contenedortexto' >
                        <div style={Ftext} className='textb1'>
                            <div style={{ height: '220px', }} className='textb2'>
                                <img src={FlexL} alt='' style={{ width: '100%', display: 'flex' }} />
                            </div>

                            <div style={Stext} className='textb2'>
                                <p>Agencia de Servicios Especializados de Consultoría en
                                    Transformación Digital</p>
                                <p style={trusted}>Especialistas en Transformación Digital</p>
                            </div>
                        </div>
                    </div>
                    <div style={containerSvg} className='textb1'>
                        <img src={Trusted} alt="trusted" className='imagenhome' style={{ marginTop: '2vw', width: '100%' }} />
                    </div>
                </section>
                <section style={container2} className='contenido2' >
                    <div style={buttons} className='buttonshome'>
                        <Link to="/Contacto" target='_blank'> <button style={buttonLeft}>CONTÁCTANOS</button></Link>
                        <Link to="/Soluciones" target='_blank'> <button style={buttonRight}>DESCUBRE MÁS</button></Link>
                    </div>
                </section>
            </div>
            <div style={sBlock}>
                <p style={title} className="titulo">¿Por qué <b>Flexware?</b></p>
                <p style={paragraf} className="parrafo">Desde su origen en el 2016, hemos entregado a nuestros clientes soluciones de valor, para la
                    transformación digital, dando reinvención a las organizaciones a través de la implementación de
                    tecnología digital, aumentando su valor en el mercado.</p>
            </div>
            <div style={sBlock2}>
                <img src={Pilares} alt="adn" style={adn} className='ADN' />
            </div>
            <div style={tBlock}>
                <section style={container2} className='contenedor2'>
                    <div style={EnATDAC} className='ATDAC'>
                        <p style={fPart} className='fPart'> Nuestros Servicios</p>
                    </div>
                    <p style={sPart} className='fPart'> FLEXWARE es una empresa dedicada a la consultoría en transformación digital.
                        Actualmente nuestro portafolio de productos contiene:
                    </p>
                </section>
            </div>
            <div style={cBlock}>
                <section style={container3} className='bloque3'>
                    <div style={fullStack} className='full'>
                        <div style={iconsSolucions}>
                            <img src={ServiciosP} alt="fullstack" style={iconS} />
                        </div>
                        <div style={paragrafSolucions} className='paragraf'>
                            <p><b>Servicios Profesionales</b></p>
                            <p>Con nuestros servicios profesionales ofrecemos bajo buenas prácticas y experiencia,
                                utilizando metodologías agiles la entrega exitosa de cada proyecto.</p>
                        </div>
                        <div className='button-solucions'>
                            <Link to="/Soluciones" target='_blank'><button style={buttonS}>VER MÁS</button></Link>
                        </div>
                    </div>


                    <div style={fullStack} className='full'>
                        <div style={iconsSolucions}>
                            <img src={Infra} alt="fullstack" style={iconS} />
                        </div>
                        <div style={paragrafSolucions} className='paragraf'>
                            <p className='title1'><b>Infraestructura</b></p>
                            <p className='paragraf1'> Proveemos  soluciones
                                para infraestructura
                                como base de datos,
                                administración multinube,
                                monitoreo de
                                dispositivos y acceso
                                remoto. </p>
                        </div>
                        <div className='button-solucions'>
                            <Link to="/Soluciones" target='_blank'><button style={buttonS}>VER MÁS</button></Link>
                        </div>
                    </div>


                    <div style={fullStack} className='full'>
                        <div style={iconsSolucions}>
                            <img src={ServP} alt="fullstack" style={iconS} />
                        </div>
                        <div style={paragrafSolucions} className='paragraf'>
                            <p style={{ textAlign: 'left' }}><b>Aplicativos</b></p>
                            <p>Con nuestros aplicativos
                                proveemos soluciones
                                que mejoran la
                                velocidad, la seguridad y
                                la operabilidad de las
                                organizaciones. </p>
                        </div>
                        <div className='button-solucions'>
                            <Link to="/Soluciones" target='_blank'><button style={buttonS}>VER MÁS</button></Link>
                        </div>
                    </div>


                    <div style={fullStack} className='full'>
                        <div style={iconsSolucions}>
                            <img src={Auto} alt="fullstack" style={iconS} />
                        </div>
                        <div style={paragrafSolucions} className='paragraf'>
                            <p className='title1'><b>Automatización</b></p>
                            <p className='paragraf1'>Brindamos soluciones para
                                automatización de procesos
                                que permitan agilizar las
                                tareas operativas de los
                                trabajadores impulsando la
                                productividad.</p>
                        </div>
                        <div className='button-solucions'>
                            <Link to="/XOC" target='_blank'><button style={buttonS}>VER MÁS</button></Link>
                        </div>
                    </div>
                </section>
            </div>
            <Partnerships />
            {/* <Costumers /> */}
            <Contactanos />
        </>
    )
}
export default Home;
