import Edificioimg from '../../assets/MuckUps Desktop/D-Contacto/Group 860.svg'
import React, { useState } from 'react'
import emailjs from 'emailjs-com'
function Contacto() {
    const contenedor = {
        width: '100%',
        height: 'auto',
        backgroundColor: '#57b14b',
        display: 'flex',
    }
    const block1 = {
        backgroundColor: 'white',
        width: '60%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'center',
    }
    const block1_1 = {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
    const containerForm = {
        width: '83%',
        height: '600px',
        display: 'grid',

    }
    const titulosform = {
        fontFamily: 'Gilroy-Regular, sans-serif',
        fontSize: '20px',
        fontWeight: '600',
        height: '100%',
        width: '100%',
        display: 'grid',
        alignItems: 'center',
    }
    const inputs = {
        height: '80%',
        width: '70%',
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: '20px',
        outline: 'none'

    }
    const labelsa = {
        display: 'flex',
        width: '100%',
        height: '48px',
        fontSize: '20px',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: '#EFEFEF',
        borderRadius: '15px',
        marginRigth: '20px',
    }
    const labels = {
        display: 'flex',
        width: '90%',
        height: '48px',
        alignItems: 'flex-start',
        marginRigth: '20px'

    }
    const inputsa = {
        height: '80%',
        width: '70%',
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: '20px',
        outline: 'none'
    }
    const labelc = {
        display: 'flex',
        width: '90%',
        height: '48px',
        alignItems: 'center',
        marginTop: '20px'
    }
    const inputm = {
        height: '110px',
        width: '100%',
        border: 'none',
        borderRadius: '15px',
        backgroundColor: '#EFEFEF',
        fontSize: '20px',
        outline: 'none',
        marginTop: '10px',
        fontFamily: 'Carme, sans-serif',
    }
    const buttonLeft = {
        height: '40px',
        width: '50%',
        backgroundColor: '#4DB8B5',
        border: 'none',
        color: '#FFFFFF',
        fontFamily: 'Carme, sans-serif',
        cursor: 'pointer',
        borderRadius: '10px',
    }
    const block2 = {
        width: '40%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
    const containerTexto = {
        width: '90%',
        height: 'auto',
        fontFamily: 'Carme, sans-serif',
        letterSpacing: '0',
        lineHeight: '30px',
        display: 'grid ',
    }
    const textos = {
        fontSize: '17px',
        lineHeight: '24px',
        color: '#FFFFFF'
    }
    const textosd = {
        maxWidth: '318px',
        fontSize: '17px',
        lineHeight: '24px',
        color: '#FFFFFF'
    }
    const titulos = {
        fontSize: '20px',
        fontWeight: '900',
        color: '#FFFFFF'
    }
    const [nombre, setNombre] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [correo, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [soluciones, setSoluciones] = useState('');
    const [nombreValido, setNombreValido] = useState(true);
    const [empresaValida, setEmpresaValida] = useState(true);
    const [correoValido, setCorreoValido] = useState(true);
    const [telefonoValido, setTelefonoValido] = useState(true);
    const [mensajeValido, setMensajeValido] = useState(true);
    const [solucionesValido, setSolucionesValido] = useState(true);

    const validarNombre = (valor) => {
        setNombre(valor);
        setNombreValido(valor.trim().length > 0);
    };

    const validarEmpresa = (valor) => {
        setEmpresa(valor);
        setEmpresaValida(valor.trim().length > 0);
    };

    const validarCorreo = (valor) => {
        setCorreo(valor);
        setCorreoValido(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valor));
    };

    const validarTelefono = (valor) => {
        setTelefono(valor);
        setTelefonoValido(/^\d+$/.test(valor) && (valor.trim().length >= 10));
    };
    const validarSoluciones = (valor) => {
        setSoluciones(valor);
        setSolucionesValido(valor.trim().length > 0);
    };

    const validarMensaje = (valor) => {
        setMensaje(valor);
        setMensajeValido(valor.trim().length > 0);
    };

    const enviarFormulario = (event) => {
        if ((nombre.valueOf === '') && (empresa.valueOf === '') && (correo.valueOf === '') && (telefono.valueOf === '') && (mensaje.valueOf === '') && (soluciones.valueOf === '')) {
            console.log('etamos correctamente bien ')
        } else if (nombreValido && empresaValida && correoValido && telefonoValido && mensajeValido && solucionesValido) {
            event.preventDefault();
            emailjs.sendForm('Atdac_Test_1', 'template_contact_ATDAC', event.target, '4UC_TKX3ga8TT2fYN')
                .then((response) => {
                    console.log(response.text);
                    alert("Su mensaje a sido enviado")
                }, (error) => {
                    console.log(error.text);
                    alert("Sucedio un error en el envio pruebe mas tarde")
                })
        }
    }
    return (
        <>
            <section style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#F39623' }}>
                <section style={contenedor} className='contenido'>
                    <section style={block1} className='contenido2'>
                        <section style={block1_1} className='contenedortexto'>
                            <p style={{
                                fontFamily: 'Gilroy-Regular, sans-serif',
                                fontSize: '40px',
                                margin: '0px',
                                marginTop: '2rem',
                                textAlign: 'center',

                            }}> ¿Como podemos <b>ayudarte</b>?</p>
                            <p style={{
                                fontFamily: 'Gilroy-Regular, sans-serif',
                                fontSize: '20px',
                                textAlign: 'center',
                            }}>¡Contactanos! Nosotros podemos asesorarte</p>
                            <div style={containerForm} className='contenedor2'>
                                <form id='Contact' style={titulosform} className='formulario'
                                    onSubmit={enviarFormulario}>
                                    <div style={labelsa}>
                                        <label>NOMBRE</label>
                                        <input
                                            type='text'
                                            id='nombre'
                                            value={nombre}
                                            onChange={(e) => validarNombre(e.target.value)}
                                            name='nombre'
                                            style={inputs}
                                            placeholder='Escriba su nombre'
                                            required

                                        ></input>
                                    </div>
                                    {!nombreValido && <span style={{ textAlign: 'center', color: 'red' }}>Por favor ingrese un nombre válido.</span>}
                                    <div style={labelsa}>
                                        <label>CORREO</label>
                                        <input
                                            value={correo}
                                            onChange={(e) => validarCorreo(e.target.value)}
                                            type='text'
                                            id="correo"
                                            name="correo"
                                            placeholder='Escriba su correo de contacto '
                                            style={inputsa}
                                            required
                                        ></input>
                                    </div>
                                    {!correoValido && <span style={{ textAlign: 'center', color: 'red' }}>Por favor ingrese un correo válido.</span>}
                                    <div style={labelsa}>
                                        <label>EMPRESA</label>
                                        <input
                                            type="text"
                                            value={empresa}
                                            onChange={(e) => validarEmpresa(e.target.value)}
                                            id="empresa"
                                            name="empresa"
                                            placeholder='Escriba el nombre de su empresa'
                                            style={inputs}
                                            required
                                        ></input>
                                    </div>
                                    {!empresaValida && <span style={{ textAlign: 'center', color: 'red' }}>Por favor ingrese el nombre de su empresa</span>}
                                    <div style={labelsa}>
                                        <label>TELEFONO</label>
                                        <input
                                            value={telefono}
                                            onChange={(e) => validarTelefono(e.target.value)}
                                            id="phone"
                                            name="telefono"
                                            placeholder='Escriba un teléfono de contacto'
                                            style={inputs}
                                            maxLength='10'
                                            required
                                        ></input>
                                    </div>
                                    {!telefonoValido && <span style={{ textAlign: 'center', color: 'red' }}>Por favor ingrese su numero de telefono de contacto</span>}

                                    <div style={labelsa}>
                                        <label>SOLUCIÓN</label>
                                        <select style={inputs}
                                            id='soluciones'
                                            type="text"
                                            value={soluciones}
                                            onChange={(e) => validarSoluciones(e.target.value)}
                                            name="soluciones"
                                            required>
                                            <option value='' disabled="disabled" >Select your option</option>
                                            <option value="Harness">Harness</option>
                                            <option value="Appdynamics">Appdynamics</option>
                                            <option value="Zscaler">Zscaler</option>
                                        </select>
                                    </div>
                                    {!solucionesValido && <span style={{ textAlign: 'center', color: 'red' }}>Por favor ingrese una opcion valida</span>}
                                    <div style={labels}>
                                        <label>MENSAJE / NECESIDAD</label>
                                    </div>
                                    <div style={labelsa}>
                                        <textarea style={inputm}
                                            value={mensaje}
                                            onChange={(e) => validarMensaje(e.target.value)}
                                            id="message"
                                            name="mensaje"
                                            placeholder='Dejenos un mensaje aquí'
                                            required
                                        ></textarea>
                                    </div>
                                    {!mensajeValido && <span style={{ textAlign: 'center', color: 'red' }}>Por favor ingrese un mensaje</span>}
                                    <div style={labelc}>
                                        <button style={buttonLeft}>ENVIAR</button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </section>
                    <section style={block2} className='contenedor2'>
                        <div style={containerTexto} className='dencontrarnos'>
                            <b style={titulos}>Dónde nos encuentras </b>
                            <img src={Edificioimg} alt="edificioimg" />
                            <p style={textosd}>Av Nuevo León 202, Cuahutemoc, Hipodromo Condesa, 06100, Ciudad de México.</p>
                            <b style={titulos}>Ventas</b>
                            <p style={textos}>55 3626 0899 y 55 6830 8147</p>
                            <b style={titulos}>Soporte</b>
                            <p style={textos}>55 5351 6618</p>
                            <b style={titulos}>Correo</b>
                            <p style={textos}> info@atdac.com.mx</p>
                        </div>
                    </section>
                </section>
            </section>
        </>
    )
}

export default Contacto;