import React from 'react'
import ReactDom from 'react-dom/client'
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Navbar from './components/Layout/navbar/navbar'
import Home from './components/Home/home'
import Contacto from './components/Contacto/contacto'
import Nosotros from './components/Nosotros/Nosotros'
import Soluciones from './components/Soluciones/Soluciones'
// import XOC from './components/XOC168/XOC168'
import Footer from './components/Layout/footer/footer'
import './index.css'
import Politicas from './components/Politicas/Politicas'
function App() {
    return (
        <div>
            <Router>
                <div className='App' styles={{ padding: '0', margin: '0', }}>
                    <Navbar />
                    <div className='content' styles={{ padding: '0', margin: '0', }}>
                        <Routes>
                            <Route path='/' element={<Navigate to="/Home" />} />
                            <Route path='/Home' element={<Home />} />
                            <Route path='/Contacto' element={<Contacto />} />
                            <Route path='/Nosotros' element={<Nosotros />} />
                            <Route path='/Soluciones' element={<Soluciones />} />
                            {/* <Route path='/XOC' element={<XOC />} />  */}
                            <Route path='/Politicas' element={<Politicas />} />
                        </Routes>
                    </div>
                    <Footer />
                </div>
            </Router>
        </div>
    )
}

const root = ReactDom.createRoot(document.getElementById('root'))
root.render(
    <>
        <App />
    </>
);