import Head from "../../assets/Image_Cambios/Nosotros1..svg";
import Meet from "../../assets/Image_Cambios/quehacemos.svg"
import Filosofia from "../../assets/Pagina_Nosotros_Flexware/paginanosotros_filosofia.svg"
import Valores from "../../assets/Pagina_Nosotros_Flexware/valores.svg"
import { Partnerships } from '../Layout/Shared/Shared'
const container = {
    width: '80%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',

}
const block1 = {
    width: '100%',
    display: 'flex',
}
const containertxt = {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}
const containerimg = {
    display: 'flex',
    width: ' 50%',
    alignItems: 'center',
    justifyContent: 'center'
}
const somos = {
    color: '#666666',
    fontFamily: 'Carme, sans-serif',
    fontSize: '75px',
    fontWeight: '300',
    letterSpacing: '0',
    width: '100%',
    margin: '0px'

}
const desc = {
    color: '#666666',
    fontFamily: 'Carme, sans-serif',
    fontSize: '20px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '22px',
    lineWidth: '10px',
    width: '100%'

}
const block2 = {
    width: '100%',
    height: '100%',
    display: 'flex',
    marginTop: 'auto',
    justifyContent: 'center',
}
const pblock2 = {
    width: '50%',
}
const pblock2_1 = {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
}
const qh = {
    width: '100%',
    color: '#000000',
    fontFamily: 'Carme, sans-serif',
    fontSize: '40px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '30px',

}
const mtext = {
    width: '100%',
    fontFamily: 'Carme, sans-serif',
    fontSize: '100% ',
    letterSpacing: '0',
    lineHeight: '100%',
    textAlign: 'justify',
}
const mtext1 = {
    width: '80%',
    fontFamily: 'Carme, sans-serif',
    fontSize: '100% ',
    letterSpacing: '0',
    lineHeight: '100%',
    textAlign: 'justify',
    marginLeft: '0px',
}

const mtext2 = {
    height: '100%',
    width: '80%',
    fontFamily: 'Carme, sans-serif',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '30px',
}
const block3 = {
    width: '100%',
    height: '100%',
    display: 'flex',
    marginTop: '50px'
}
const block3_1 = {
    width: '40%',
    display: 'flex',
    flexDirection: 'column'
}
const ftxt = {
    width: '100%',
    color: '#000000',
    fontFamily: 'Gilroy-ExtraBold, sans-serif',
    fontSize: '40px',
    marginLeft: '30px',
    fontweight: '600',
    lineHeight: '0px',
    textAlign: 'center'
}
const block3_2 = {
    width: '60%',
    display: 'grid',
}
const block3_3 = {
    height: '100%',
    width: '90%',
    display: 'grid',
    marginLeft: '30px'
}
const block3_3_1 = {
    width: '100%',
    fontFamily: 'Carme, sans-serif',
    fontSize: '19px',
    fontWeight: '800',
    letterSpacing: '0',
    lineHeight: '30px',
    color: '#000000',
}
const block3_3_2 = {
    width: '100%',
    fontFamily: 'Carme, sans-serif',
    fontSize: '19px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '30px',
    color: '#000000',
}
const block3_3_3 = {
    height: '100%',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-around'
}

function Nosotros() {
    return (
        <>
            <section style={{ display: 'flex', justifyContent: 'center', background: '#E0E0E0', height: '100%', width: 'auto' }}>
                <section style={container} className='contenido'>
                    <section style={block1} className='contenido2' >
                        <section style={containertxt} className='contenedortexto'>
                            <div style={somos} >
                                <p style={{ margin: '0', textAlign: 'center' }}>¿Quiénes <b>Somos</b>?</p>
                            </div>
                            <div style={desc} className="textb2">
                                <p style={mtext} >Flexware es una empresa de Servicios Especializados en
                                    Consultoría para la Transformación Digital. Nuestro
                                    compromiso es asegurar el crecimiento de su empresa
                                    implementando soluciones innovadoras brindando la
                                    tecnología necesaria para enfrentar las oportunidades
                                    que tenga en el mercado y que le permita alcanzar su
                                    objetivo principal: el aumento del valor de su negocio. </p>
                                <p>Cada miembro de nuestra organización, así como
                                    nuestras alianzas estratégicas tienen la finalidad de
                                    superar las expectativas de nuestros clientes.</p>
                            </div>
                        </section>
                        <section style={containerimg}>
                            <img src={Head} alt="" style={{ height: '450px', width: '100%', padding: '10px' }} className='imagennosotros' />
                        </section>
                    </section>
                </section>
            </section>
            <section style={{ display: 'flex', justifyContent: 'center', height: '100%', width: 'auto' }}>
                <section style={container} className='contenido'>
                    <section style={block2} className='contenido2' >
                        <div style={pblock2_1} className='contenedortexto'>
                            <img src={Meet} alt="Que hacemos" style={{ width: '60%', display: 'flex', marginRight: '10%' }} className="iqhace" />
                        </div>
                        <div style={pblock2} className="textb3">
                            <p style={qh}>¿Qué <b>hacemos</b>?</p>
                            <p style={mtext1} className="textoqh" >
                                Actualmente Flexware  busca ofrecer
                                soluciones a nuestros clientes para agregar
                                valor a su negocio y lograr ser empresa
                                predilecta en implementación de TIC´S.
                            </p>
                            <ul style={mtext2}>
                                <li>Servicio Profesionales</li>
                                <li>Infraestructura</li>
                                <li>Aplicativos</li>
                                <li>Automatización</li>
                            </ul>
                        </div>
                    </section>
                </section>
            </section>
            <section style={{ display: 'flex', justifyContent: 'center', height: '100%', width: 'auto', }}>
                <section style={container} className='contenido1'>
                    <section style={block3} className='contenido2'>
                        <div style={block3_1} className='contenedortexto'>
                            <p style={ftxt}><b>Filosofía</b></p>
                            <img src={Filosofia} alt="Filosofia" style={{
                                height: '100%',
                                width: '100%',
                            }} />
                        </div>
                        <div style={block3_2} className="block3_2">
                            <div style={block3_3} className="block3_3">
                                <div style={block3_3} className="block3_3_1">
                                    <b style={block3_3_1} className="textoqh">Misión</b>
                                    <p style={block3_3_2} className="textoqh1">Convertirnos en el proveedor preferido de soluciones y
                                        servicios de IT de nuestros clientes, impulsando la
                                        transformación digital que soporte la nueva forma de
                                        hacer negocios en la nueva realidad. </p>
                                    <b style={block3_3_1} className="textoqh">Visión</b>
                                    <p style={block3_3_2} className="textoqh1">Facilitar a nuestros clientes la adopción de tecnologías
                                        disruptivas que incrementen la satisfacción de sus
                                        clientes internos y externos, incrementado el retorno de
                                        cada inversión de TI.</p>
                                    <b style={block3_3_1} className="textoqh">Valores</b>
                                </div>
                                <div style={block3_3_3} className="block3_3_1">
                                    <img src={Valores} alt="valores" style={{ width: '100%', height: '90%' }}></img>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </section>
            <Partnerships />
        </>
    );
}
export default Nosotros;